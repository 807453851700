<template>
  <div>
    <v-container v-if="operation" fluid style="height: calc(100vh - 88px)" class="grey lighten-2">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <v-card rounded="0">
            <v-card-text>
              <v-container fluid class="my-0 py-0">
                <v-row align="center">
                  <v-col cols="1">
                    <v-icon size="50" @click="goToStuffing">mdi-arrow-left</v-icon>
                  </v-col>
                  <v-col cols="11" class="text-left">
                    <div class="text-subtitle-1">STUFFING</div>
                    <div class="text-h5 font-weight-bold blue--text">
                      {{ operation.orderReference }} <span class="text-subtitle-1 font-weight-light pl-4">{{ operation.orderExtCode }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row align="center" no-gutters style="height: calc(100vh - 192px)">
        <v-col cols="12" class="fill-height">
          <v-container fluid class="fill-height mx-0 px-0">
            <v-row no-gutters class="fill-height">
              <v-col cols="5">
                <!-- {{ selectedToStuff }} -->
                <v-card rounded="0" class="fill-height" ref="goodsCard">
                  <v-card-title class="grey lighten-4">
                    <v-container fluid class="ma-0 pa-0">
                      <v-row align="center">
                        <v-col cols="12" class="text-h6 text-uppercase font-weight-medium text-right my-0 py-0">{{ tLabel("Goods") }}</v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="12" class="text-subtitle-1 text-uppercase text-left my-0 py-0">&nbsp;</v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text style="height: calc(100vh - 300px)" class="scroller">
                    <v-container fluid>
                      <v-row align="center">
                        <v-col cols="4" v-for="goo in goodsToStuff" :key="goo.visitId">
                          <!-- && operation.unit.ata && operation.unit.ata !== null AGGIUNGERE!!!!!!!!! -->
                          <OrderOpGoodsOperativeCard
                            :goods="goo"
                            :selectable="!!goo.ata && !!operation.unit && operation.unit.locked === false"
                            @goodsSelected="onGoodsSelected"
                            :removable="true"
                            @goodsRemoving="onGoodsRemoving"
                            timeArrivalVisible
                            positionVisible
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-btn
                    fab
                    height="100"
                    width="100"
                    class="floatCenter"
                    elevation="9"
                    color="primary"
                    v-if="!operation.unit || operation.unit === null || operation.unit.locked === false"
                    @click="prepareAddGoods"
                  >
                    <v-icon size="60">mdi-plus</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-container fluid class="fill-height pa-0 ma-0">
                  <v-row class="fill-height">
                    <v-col cols="12" align-self="center" class="text-center">
                      <v-btn
                        fab
                        outlined
                        height="140"
                        width="140"
                        elevation="6"
                        color="secondary"
                        v-if="operation.unit && operation.unit !== null && operation.unit.locked === false && selectedToStuff.length > 0"
                        @click="prepareStuff"
                      >
                        <v-icon size="140"> mdi-chevron-right </v-icon>
                      </v-btn>
                      <v-icon v-else color="secondary" size="140" disabled> mdi-chevron-right </v-icon>
                    </v-col>
                    <v-col cols="12" align-self="center" class="text-center">
                      <v-btn
                        fab
                        outlined
                        height="140"
                        width="140"
                        elevation="6"
                        color="secondary"
                        v-if="operation.unit && operation.unit !== null && operation.unit.locked === false && selectedStuffed.length > 0"
                        @click="unstuffSelected"
                      >
                        <v-icon size="140"> mdi-chevron-left </v-icon>
                      </v-btn>
                      <v-icon v-else color="secondary" size="140" disabled> mdi-chevron-left </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="5">
                <!-- {{ selectedStuffed }} -->
                <v-card img="assets/container-icon.png" rounded="0" class="fill-height" style="border: 3px solid grey" ref="unitCard">
                  <v-card-title :class="!!operation.unit && operation.unit.locked === true ? 'green lighten-5' : 'light-blue lighten-5'">
                    <v-container fluid class="ma-0 pa-0">
                      <v-row align="center">
                        <v-col v-if="!operation.unit || operation.unit === null" cols="4" class="my-0 py-0"></v-col>
                        <v-col v-else-if="!!operation.unit.ata" cols="4" class="text-subtitle-2 text-uppercase text-left my-0 py-0">
                          <v-icon color="secondary">mdi-warehouse</v-icon>
                        </v-col>
                        <v-col v-else cols="4" class="text-subtitle-2 text-uppercase text-left my-0 py-0">
                          {{ operation.unit.eta | formatDateTimeFromDate }} <v-icon color="secondary" class="ml-2">mdi-clock-fast</v-icon>
                        </v-col>
                        <v-col cols="4" class="text-subtitle-1 text-uppercase text-center my-0 py-0">
                          <v-icon color="secondary">mdi-weight-kilogram</v-icon>
                        </v-col>
                        <v-col cols="4" class="text-h6 text-uppercase font-weight-medium text-right my-0 py-0">
                          <v-btn
                            class="mr-2"
                            small
                            icon
                            :title="tLabel('Info unit')"
                            @click="unitInfoDialogVisible = true"
                            v-if="!!operation.unit && operation.unit.locked === true"
                            ><v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                          <v-btn class="mr-2" small icon :title="tLabel('Assegna unit')" @click="prepareAssignUnit" v-else-if="goodsStuffed.length === 0"
                            ><v-icon :color="assignUnitColor" @mouseover="assignUnitColor = 'blue darken-3'" @mouseleave="assignUnitColor = null">mdi-pencil</v-icon></v-btn
                          >{{ !!operation.unit ? operation.unit.number : "UNIT NON ASSEGNATA" }}
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="4" class="text-subtitle-1 text-uppercase text-left my-0 py-0">
                          {{ !!operation.unit ? (!!operation.unit.position ? operation.unit.position : "N/A") : "&nbsp;" }}
                        </v-col>
                        <v-col cols="4" class="text-subtitle-1 text-uppercase text-center my-0 py-0">
                          {{ operation.unit ? (operation.unit.weight ? operation.unit.weight + " KG" : "N/A") : "" }}
                        </v-col>
                        <v-col cols="4" class="text-subtitle-1 text-uppercase text-right my-0 py-0"> {{ !!operation.unit ? operation.unit.type : "&nbsp;" }}</v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text style="height: calc(100vh - 300px)" class="scroller">
                    <v-container fluid>
                      <v-row align="center">
                        <v-col cols="4" v-for="goo in goodsStuffed" :key="goo.visitId">
                          <OrderOpGoodsOperativeCard
                            :goods="goo"
                            @goodsSelected="onGoodsSelected"
                            :color="!!operation.unit && operation.unit.locked === true ? 'green' : 'blue'"
                            :selectable="!!goo.ata && !!operation.unit && operation.unit.locked === false"
                            timeArrivalVisible
                            positionVisible
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <div v-if="operation.unit === null"></div>
                  <v-btn
                    fab
                    height="100"
                    width="100"
                    class="floatCenter"
                    elevation="9"
                    color="warning"
                    v-else-if="operation.unit.locked === false"
                    @click="sealsDialogVisible = true"
                  >
                    <v-icon size="50">mdi-lock-open-variant</v-icon>
                  </v-btn>
                  <v-btn fab height="100" width="100" class="floatCenter" elevation="9" color="green" v-else @click="uncloseUnit">
                    <v-icon size="50" color="white">mdi-lock</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    height="60"
                    width="60"
                    class="floatTopRight"
                    elevation="9"
                    :color="operation.unit.weight ? 'success' : 'primary'"
                    v-if="!!operation.unit"
                    @click="prepareSetUnitWeight"
                    :title="tLabel('Aggiorna peso unit')"
                  >
                    <v-icon color="white" size="30">mdi-weight-kilogram</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="sealsDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Chiusura unit - Sigilli") }}</v-card-title>
        <v-card-text>
          <v-container fluid v-if="operation && operation !== null && operation.unit && operation.unit !== null">
            <v-row align="center">
              <v-col cols="12">
                <v-text-field v-model="operation.unit.seal1" :label="tLabel('Sigillo 1')" @input="(val) => (operation.unit.seal1 = val ? val.toUpperCase() : null)" />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <v-text-field v-model="operation.unit.seal2" :label="tLabel('Sigillo 2')" @input="(val) => (operation.unit.seal2 = val ? val.toUpperCase() : null)" />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <v-text-field v-model="operation.unit.seal3" :label="tLabel('Sigillo 3')" @input="(val) => (operation.unit.seal3 = val ? val.toUpperCase() : null)" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="sealsDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn
            color="success"
            @click="
              closeUnit();
              sealsDialogVisible = false;
            "
            ><v-icon class="pr-2">mdi-check</v-icon>{{ tLabel("Conferma") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unitInfoDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Unit info") }}</v-card-title>
        <v-card-text>
          <v-container fluid v-if="operation && operation !== null && operation.unit && operation.unit !== null">
            <v-row align="center">
              <v-col cols="12">
                <LabeledContent :value="operation.unit.seal1" :label="tLabel('Sigillo 1')" />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <LabeledContent :value="operation.unit.seal2" :label="tLabel('Sigillo 2')" />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                <LabeledContent :value="operation.unit.seal3" :label="tLabel('Sigillo 3')" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="unitInfoDialogVisible = false" class="mr-2"><v-icon>mdi-close</v-icon>{{ tLabel("Chiudi") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addGoodsDialogVisible" max-width="80%" persistent>
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Aggiungi merce") }}</v-card-title>
        <v-card-text>
          <GoodsSelectionPerOperationPanel :goods="goodsAvailable" :goodsToAdd="goodsToAdd" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="addGoodsDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="addGoods"><v-icon>mdi-plus</v-icon>{{ tLabel("Aggiungi") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeGoodsDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Rimuovi merce") }}</v-card-title>
        <v-card-text class="text-h6 py-2" v-if="goodsToRemove !== null">
          Rimuovere dall'operazione di stuffing la merce {{ goodsToRemove.code }}
          {{ goodsToRemove.description && goodsToRemove.description !== null && goodsToRemove.description !== "" ? " - " + goodsToRemove.description : "" }} ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="removeGoodsDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="removeGoods"><v-icon>mdi-delete</v-icon>{{ tLabel("Rimuovi") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unitWeightDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Pesa") }}</v-card-title>
        <v-card-text class="text-h6 py-2">
          <v-form ref="setUnitWeightForm">
            <v-text-field :label="tLabelRequired('Peso unit (Kg)')" v-model.number="unitWeight" :rules="[rules.required, rules.numeric]"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="unitWeightDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="setUnitWeight" :disabled="unitWeight === null"><v-icon class="pr-2">mdi-content-save</v-icon>{{ tLabel("Save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unitAssignDialogVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Assegna unit") }}</v-card-title>
        <v-card-text>
          <UnitSelectionPerOperationPanel :units="unitsAvailable" :unitsToAdd="unitsToAssign" singleSelection />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="unitAssignDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="assignUnit"><v-icon>mdi-check</v-icon>{{ tLabel("Assegna") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="stuffDialogVisible" max-width="80%">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Stuff merce") }}</v-card-title>
        <v-card-text>
          <v-container fluid class="my-2 fill-height scroller" style="max-height: 550px">
            <v-row align="center">
              <v-col cols="12">
                <!-- {{ selectedToStuffObjs }} -->
                <div v-for="gts in selectedToStuffObjs" :key="gts.visitId" class="mb-6">
                  <!-- {{ gts }} -->
                  <v-card rounded="0" class="mb-1 grey lighten-4">
                    <v-card-text>
                      <v-row align="center" class="text-subtitle-1 font-weight-medium">
                        <v-col cols="1">
                          <v-icon x-large>mdi-dolly</v-icon>
                        </v-col>
                        <v-col cols="2">
                          Codice
                          <div class="blue--text text-h6 font-weight-normal">{{ gts.code && gts.code !== null ? gts.code : "N/A" }}</div>
                        </v-col>
                        <v-col cols="3">
                          Descrizione
                          <div class="font-weight-light">{{ gts.description && gts.description !== null ? gts.description : "N/A" }}</div>
                        </v-col>
                        <v-col cols="2">
                          Peso lordo
                          <div v-if="gts.weight && gts.weight !== null" class="font-weight-light">{{ gts.weight || numberRounded2WithComma }} Kg</div>
                          <div v-else class="font-weight-light">N/A</div>
                        </v-col>
                        <v-col cols="4">
                          Colli
                          <div class="font-weight-light">{{ gts.quantity && gts.quantity !== null ? gts.quantity : "N/A" }}</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card v-for="stuff in gts.stuffObj" :key="stuff.id" rounded="0" class="mb-1 grey lighten-3">
                    <v-card-text>
                      <v-row align="center" class="text-h6">
                        <v-col cols="2" class="text-h5"> STUFFING </v-col>
                        <v-col cols="6" class="text-h5">
                          <v-icon x-large>mdi-chevron-right</v-icon>
                        </v-col>
                        <v-col cols="4" class="text-right">
                          <v-container fluid class="ma-0 pa-0">
                            <v-row align="center">
                              <v-col cols="6" class="text-right">
                                <v-text-field background-color="white" :label="tLabel('Colli')" v-model.number="stuff.quantity" :rules="[rules.numeric]" />
                              </v-col>
                              <v-col cols="1" class="text-center">/</v-col>
                              <v-col cols="5" class="text-left">{{ gts.quantity }}</v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="stuffDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="stuffSelected"><v-icon>mdi-check</v-icon>{{ tLabel("Esegui") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormatMixins from "../../../mixins/FormatsMixins";
import ToastMixin from "../../../mixins/ToastMixin";
import OrderOpGoodsOperativeCard from "@/components/yard/OrderOpGoodsOperativeCard";
import UnitSelectionPerOperationPanel from "@/components/yard/UnitSelectionPerOperationPanel";
import GoodsSelectionPerOperationPanel from "@/components/yard/GoodsSelectionPerOperationPanel";
import LabeledContent from "@/components/yard/LabeledContent.vue";

export default {
  name: "StuffingOperation",
  data() {
    return {
      operation: null,
      selectedToStuff: [],
      selectedStuffed: [],
      sealsDialogVisible: false,
      unitInfoDialogVisible: false,
      unitAssignDialogVisible: false,
      unitsToAssign: [],
      unitsAvailable: [],
      addGoodsDialogVisible: false,
      removeGoodsDialogVisible: false,
      goodsAvailable: [],
      goodsToAdd: [],
      goodsToRemove: null,
      assignUnitColor: null,
      unitWeightDialogVisible: false,
      unitWeight: null,
      selectedToStuffObjs: [],
      stuffDialogVisible: false,
    };
  },
  props: {
    operationId: {
      type: String,
      required: true,
    },
    attrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatMixins, ToastMixin],
  components: { OrderOpGoodsOperativeCard, UnitSelectionPerOperationPanel, GoodsSelectionPerOperationPanel, LabeledContent },
  async mounted() {
    this.operation = await this.$api.yardData.getOperationById(this.operationId);
    console.log("operation", this.operation, JSON.stringify(this.operation));
  },
  computed: {
    goodsToStuff() {
      if (this.operation !== null && this.operation.goods && this.operation.goods !== null) {
        return this.operation.goods.filter((g) => g.stuffed === false);
      }
      return [];
    },
    goodsStuffed() {
      if (this.operation !== null && this.operation.goods && this.operation.goods !== null) {
        return this.operation.goods.filter((g) => g.stuffed === true);
      }
      return [];
    },
  },
  methods: {
    goToStuffing() {
      if (this.attrs) {
        this.attrs.operationFromId = this.operationId;
      }
      this.$router.push({
        name: "Stuffing",
        params: {
          attrs: this.attrs,
        },
      });
    },
    onGoodsSelected(gs) {
      // console.log("onGoodsSelected --->", gs);
      if (gs.selected === true) {
        // aggiungere id alla lista giusta tra stuff e stuffed
        if (this.goodsToStuff && this.goodsToStuff !== null && this.goodsToStuff.length > 0 && this.goodsToStuff.some((g) => g.handlingId === gs.handlingId)) {
          this.selectedToStuff.push(gs.handlingId);
          return;
        }
        if (this.goodsStuffed && this.goodsStuffed !== null && this.goodsStuffed.length > 0 && this.goodsStuffed.some((g) => g.handlingId === gs.handlingId)) {
          this.selectedStuffed.push(gs.handlingId);
          return;
        }
      } else {
        // rimuovere dalla lista che lo contiene
        if (this.selectedToStuff.length > 0 && this.selectedToStuff.some((gHandlingId) => gHandlingId === gs.handlingId)) {
          var index = this.selectedToStuff.indexOf(gs.handlingId);
          if (index > -1) {
            this.selectedToStuff.splice(index, 1);
          }
          return;
        }
        if (this.selectedStuffed.length > 0 && this.selectedStuffed.some((gHandlingId) => gHandlingId === gs.handlingId)) {
          var index = this.selectedStuffed.indexOf(gs.handlingId);
          if (index > -1) {
            this.selectedStuffed.splice(index, 1);
          }
          return;
        }
      }
    },
    async prepareAssignUnit() {
      this.unitsToAssign = [];
      if (this.operation.unit && this.operation.unit !== null) {
        this.unitsToAssign.push(JSON.parse(JSON.stringify(this.operation.unit)));
      }
      this.unitsAvailable = await this.$api.yardData.getUnitsNotExited("stuffing");
      this.unitAssignDialogVisible = true;
    },
    async assignUnit() {
      try {
        this.operation = await this.$api.yardData.setUnit(this.operationId, this.unitsToAssign[0]);
        this.showSuccess("Unit assegnata correttamente all'operazione di stuffing.");
        this.unitAssignDialogVisible = false;
      } catch (ex) {
        this.showError("Errore assegnazione unit: " + ex.detailMessage);
      }
    },
    onGoodsRemoving(gr) {
      this.goodsToRemove = JSON.parse(JSON.stringify(gr));
      this.removeGoodsDialogVisible = true;
    },
    async removeGoods() {
      try {
        this.operation = await this.$api.yardData.removeGoods(this.operationId, this.goodsToRemove.visitId);
        this.showSuccess("Merce rimossa correttamente dall'operazione di stuffing.");
      } catch (ex) {
        this.showError("Errore rimozione merce: " + ex.detailMessage);
      } finally {
        this.removeGoodsDialogVisible = false;
      }
    },
    async prepareAddGoods() {
      this.goodsAvailable = await this.$api.yardData.getGoodsNotExitedAndNotInOrders();
      this.goodsToAdd = [];
      this.addGoodsDialogVisible = true;
    },
    async addGoods() {
      try {
        this.operation = await this.$api.yardData.addGoods(this.operationId, this.goodsToAdd);
        this.showSuccess("Merce aggiunta correttamente all'operazione di stuffing.");
        this.addGoodsDialogVisible = false;
      } catch (ex) {
        this.showError("Errore aggiunta merce: " + ex.detailMessage);
      }
    },
    prepareStuff() {
      console.log("prepareStuff", JSON.stringify(this.goodsToStuff), JSON.stringify(this.selectedToStuff));
      this.selectedToStuffObjs = this.goodsToStuff.filter((gts) => this.selectedToStuff.some((sts) => sts === gts.handlingId));
      this.selectedToStuffObjs.forEach((sts) => {
        sts.stuffObj = [
          {
            id: 1,
            quantity: sts.quantity,
          },
        ];
      });
      this.stuffDialogVisible = true;
    },
    async stuffSelected() {
      try {
        console.log("stuffSelected selectedToStuffObjs", this.selectedToStuffObjs);
        var goos = [];
        this.selectedToStuffObjs.forEach((sts) => {
          sts.stuffObj.forEach((so) => {
            goos.push({
              handlingId: sts.handlingId,
              code: sts.code,
              quantity: so.quantity,
            });
          });
        });
        console.log("stuffSelected goos", goos);
        this.operation = await this.$api.yardData.stuff(this.operationId, goos);
        this.selectedToStuff = [];
        this.stuffDialogVisible = false;
      } catch (ex) {
        this.showError("Errore stuffing merce: " + ex.detailMessage);
      }
    },
    async unstuffSelected() {
      try {
        var selectedStuffedObjs = this.goodsStuffed.filter((gts) => this.selectedStuffed.some((sts) => sts === gts.handlingId));
        this.operation = await this.$api.yardData.unstuff(this.operationId, selectedStuffedObjs);
        this.selectedStuffed = [];
      } catch (ex) {
        this.showError("Errore undo stuffing merce: " + ex.detailMessage);
      }
    },
    async closeUnit() {
      if (this.operation !== null) {
        this.operation = await this.$api.yardData.closeUnit(this.operationId, this.operation.unit);
      }
    },
    async uncloseUnit() {
      this.operation = await this.$api.yardData.uncloseUnit(this.operationId);
    },
    prepareSetUnitWeight() {
      this.unitWeight = this.operation.unit.weight;
      this.unitWeightDialogVisible = true;
    },
    async setUnitWeight() {
      const valid = this.$refs.setUnitWeightForm.validate();
      if (!valid) {
        return;
      }
      try {
        let unitVisit = await this.$api.yardData.setVisitWeight(this.operation.unit.visitId, this.unitWeight);
        this.operation.unit.weight = unitVisit.grossweight;
        this.showSuccess("Peso unit impostato correttamente.");
        this.$refs.setUnitWeightForm.resetValidation();
        this.unitWeightDialogVisible = false;
      } catch (ex) {
        this.showError("Errore impostazione peso unit: " + ex.detailMessage);
      }
    },
  },
};
</script>

<style scoped>
.floatCenter {
  position: absolute;
  top: 82%;
  left: 5%;
  opacity: 0.6;
}

.floatCenter:hover {
  position: absolute;
  top: 82%;
  left: 5%;
  opacity: 1;
}

.floatTopRight {
  position: absolute;
  top: 76%;
  left: 18%;
  opacity: 0.6;
}
.floatTopRight:hover {
  opacity: 1;
}

.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pointer {
  cursor: pointer;
}
</style>
